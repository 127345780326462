import './spinner.scss';

const Spinner = () => {
    return (
        <>
            <div className="skeleton">
                <div className='container'>
                    <div className="pulse skeleton__header"></div>
                    <div className="pulse skeleton__block"></div>
                </div>
            </div>
        </>
    )
}

export default Spinner;