import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { NavLink} from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import { useLocation } from 'react-router-dom';
import * as Yup from "yup";
import ring from "./../../resourses/img/icons/black-ring.svg";
import "./headerFeedback.scss";
import "./footerFeedback.scss";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const phoneMask = () => {
  let phoneInputs = document.querySelectorAll("input[name='tel']");
  let getInputNumbersValue = function (input) {
    return input.value.replace(/\D/g, '');
  }

  let onPhonePaste = function (e) {
    let input = e.target,
      inputNumbersValue = getInputNumbersValue(input);
    let pasted = e.clipboardData || window.сlipboardData;
    if (pasted) {
      let pastedText = pasted.getData('Text');
      if (/\D/g.test(pastedText)) {
        input.value = inputNumbersValue;
        return;
      }
    }
  }

  let onPhoneInput = function (e) {
    let input = e.target,
        inputNumbersValue = getInputNumbersValue(input),
        selectionStart = input.selectionStart,
        formattedInputValue = "";

    if (!inputNumbersValue) {
        return input.value = "";
    }

    if (input.value.length !== selectionStart) {
        if (e.data && /\D/g.test(e.data)) {
            input.value = inputNumbersValue;
        }
        return;
    }

  if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
    if (inputNumbersValue[0] === "9") inputNumbersValue = "7" + inputNumbersValue;
    let firstSymbols = (inputNumbersValue[0] === "8") ? "8" : "+7";
    formattedInputValue = input.value = firstSymbols + " ";
    if (inputNumbersValue.length > 1) {
      formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
    }
    if (inputNumbersValue.length >= 5) {
      formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
    }
    if (inputNumbersValue.length >= 8) {
      formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
    }
    if (inputNumbersValue.length >= 10) {
      formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
    }

  } else {
    formattedInputValue = '+' + inputNumbersValue.substring(0, 16);
  }
  input.value = formattedInputValue;
}
  let onPhoneKeyDown = function (e) {
    let inputValue = e.target.value.replace(/\D/g, '');
    if (e.keyCode === 8 && inputValue.length === 1) {
        e.target.value = "";
    }
  }
  for(let phoneInput of phoneInputs) {
    phoneInput.addEventListener('keydown', onPhoneKeyDown);
    phoneInput.addEventListener('input', onPhoneInput, false);
    phoneInput.addEventListener('paste', onPhonePaste, false);
  }
  
}

const MyTextInput = ({ label, errorname, ...props}) => {
  const [field, meta] = useField(props);
  
  window.addEventListener("DOMContentLoaded", () => {
    phoneMask();
  });
  return (
    <>
      <label className="feedback-header__label">
        <input {...field} {...props} className="feedback-header__input" />
        {meta.touched && meta.error ? <div className={errorname}>{meta.error}</div> : null}
      </label>
    </>
  );
  
};
const MyTextInputFooter = ({ label, errorname, ...props }) => {
 
  const [field, meta] = useField(props);
  window.addEventListener("DOMContentLoaded", () => {
    phoneMask();
  });
  return (
    <>
      <label className="feedback-header__label">
        <input {...field} {...props} className="feedback__input"  />
        {meta.touched && meta.error ? <div className={errorname}>{meta.error}</div> : null}
      </label>
    </>
  );
};


const FeedbackHeader = ({ onGetForm, footer }) => {
  const {pathname} = useLocation();
  const app = useRef();
  
  useEffect(() => {
    
    let ctx = gsap.context((self) => {
      const inputs = self.selector('.feedback__input');
      inputs.forEach((input) => {
        gsap.from(input, {
          width: 200,
          scrollTrigger: {
            trigger: input,
            start: 'center bottom',
            end: '250 bottom',
            scrub: true,
          }
        });
      })
      
    }, app);
    return () => ctx.revert();
 
  }, [pathname])
  let form = document.querySelector('form');
    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
    const HeaderFeed = () => {
      useEffect(() => {
          const sendHeaderBtn = document.querySelector('.feedback-header__btn')
          sendHeaderBtn.setAttribute('disabled', true)
       
      }, [])
      const [nameVal, setName] = useState('');
      const [email, setEmail] = useState('');
      const [tel, setTel] = useState('');
      const [text, setText]  = useState('');
      const [clazz, setClazz] = useState('feedback__responce feedback__responce--header');
    
      const onChangeDisabled = () => {
        let errorMessage = document.querySelectorAll('.error--header');
        const sendHeaderBtn = document.querySelector('.feedback-header__btn')

        if(nameVal && email && tel.length >= 16 && errorMessage.length == 0) {
          sendHeaderBtn.removeAttribute('disabled')
        } else {
          sendHeaderBtn.setAttribute('disabled', true)
        }
      }
      const onCloseResponce = () => {
        onGetForm();
        document.querySelector('.feedback-header').classList.remove('feedback-header__active');
        document.querySelector('.app').classList.remove('app__lock')
        document.querySelector('.create').classList.remove('create__active');
        setClazz('feedback__responce feedback__responce--header');
        document.querySelector('body').classList.remove('lock');
      }
      const onSubmit = async function (event) {
      event.preventDefault();
      let formData = new FormData();
      formData.append("name", nameVal);
      formData.append("email", email);
      formData.append("tel", tel);
      formData.append("text", text);
    
      let response = await fetch('/mailer/mail.php', {
          method: 'POST',
          body: formData
        });

      if (response.ok) {
        setName('');
        setEmail('');
        setTel('');
        setText('');
        setClazz('feedback__responce feedback__responce--header active')
        document.querySelector('.feedback-header').scrollTo(0, 0);
        document.querySelector('.app').classList.remove('app__lock')
        document.querySelector('.create').classList.remove('create__active');
        document.querySelector('body').scrollTo(0, 0);
        document.querySelector('body').classList.add('lock');
      } else {
          console.log("error: " + response.status);
          setName('');
        setEmail('');
        setTel('');
        setText('');
        setClazz('feedback__responce feedback__responce--header active')
        document.querySelector('.feedback-header').scrollTo(0, 0);
        document.querySelector('.app').classList.remove('app__lock')
        document.querySelector('.create').classList.remove('create__active');
        document.querySelector('body').scrollTo(0, 0);
        document.querySelector('body').classList.add('lock');
      }
    }
      return (
        <>
            <div className="create">
              <nav className="create__header">
              <div className="head-anim menu__logo">
                <NavLink to="/">
                    <svg width="168" height="31" viewBox="0 0 168 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M41.5736 0.0454102V30.1982H35.3514V13.8673L28.0691 30.1526H13.5506L6.2683 13.8217V30.1526H0V0.0454102H6.2683L20.2798 30.1982L35.3514 0.0454102H41.5736Z" fill="black"/>
                        <path d="M56.7371 0.0454102V30.1982H50.4688V0.0454102H56.7371Z" fill="black"/>
                        <path d="M123.523 17.7904L128.915 30.1982H121.725L116.84 19.0677C115.826 19.1133 115.088 19.1589 114.581 19.1589H71.7632V30.2438H65.3105V0.0454102H114.535C118.776 0.0454102 122.324 0.729664 125.182 2.09817C128.04 3.46668 129.469 6.11246 129.469 10.0355C129.561 13.7305 127.533 16.3307 123.523 17.7904ZM114.581 13.0462C116.563 13.0462 118.13 12.955 119.19 12.7269C120.25 12.4988 121.034 12.1795 121.449 11.6777C121.864 11.2216 122.094 10.5829 122.094 9.76182C122.094 8.94071 121.864 8.30208 121.449 7.84591C121.034 7.38974 120.25 7.0248 119.19 6.79672C118.13 6.56863 116.563 6.4774 114.581 6.4774H71.7632V13.0462H114.581Z" fill="black"/>
                        <path d="M160.949 30.1528L158.506 24.5875H144.172L141.729 30.1528H134.631L147.721 0H154.818L168 30.1528H160.949ZM146.937 18.2012L155.694 18.1555L151.316 8.11982L146.937 18.2012Z" fill="black"/>
                    </svg>
                  </NavLink>
                </div>
                <ul className=" menu__wrapper head-anim">
                  <li className="menu__item ">
                      <NavLink 
                              to="/cases/"
                              className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                          
                          }>кейсы</NavLink>
                  </li>
                  <li className="menu__item head-anim">
                      <NavLink to="/services/"
                            className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active" : ""
                          }>студия</NavLink>
                  </li>
              </ul>
              </nav>
              <p className="create__text">
                  Создаем креатив <br/> для бизнес целей
              </p>
            </div>
            <Form className='feedback-header'>
              <div className={clazz}>
              <button className="feedback__close" type="button" onClick={onCloseResponce}>
              <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63" fill="none">
              <circle cx="31.5" cy="31.5" r="31.5" fill="#E94235"/>
              <path d="M43.3345 21.707L19.9999 45.0416" stroke="white" stroke-width="3" stroke-linecap="round"/>
              <path d="M44.042 44.3345L20.7075 20.9999" stroke="white" stroke-width="3" stroke-linecap="round"/>
              </svg>
              </button>
              <div className="feedback__wrapper feedback__wrapper--header">
                <div className="feedback__logo">
                  <svg xmlns="http://www.w3.org/2000/svg" width="186" height="187" viewBox="0 0 186 187" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M93 0C93 51.6386 51.3625 93.5 0 93.5C51.3625 93.5 93 135.361 93 187C93 135.361 134.638 93.5 186 93.5C134.638 93.5 93 51.6386 93 0Z" fill="white"/>
                  </svg>
                </div>
                <p>Спасибо, что написали нам, мы свяжемся с вами как можно скорее</p>
                <div className="feedback__logo feedback__logo--small">
                  <svg xmlns="http://www.w3.org/2000/svg" width="62" height="63" viewBox="0 0 62 63" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M31 0C31 17.397 17.1208 31.5 0 31.5C17.1208 31.5 31 45.603 31 63C31 45.603 44.8792 31.5 62 31.5C44.8792 31.5 31 17.397 31 0Z" fill="white"/>
                  </svg>
                </div>
              </div>
              
            </div>
          <button className="feedback-header__ring" type="button" onClick={onGetForm}>
            <img src={ring} />
          </button>
          <h2 className="feedback-header__title">Обсудить проект</h2>
          <MyTextInput id="name" name="name" type="text" placeholder="имя" autoComplete="off" 
          errorname="error error--header"
          value={nameVal}
          onInput={(e) => {setName(e.target.value); onChangeDisabled()}}/>
          <MyTextInput id="mail" name="email" type="email" placeholder="main@mail.com" autoComplete="off" 
          errorname="error error--header"
          value={email}
          onInput={(e) => {setEmail(e.target.value); onChangeDisabled()}}/>
          <MyTextInput  id="tel" name="tel" type="tel" placeholder="+7 (9--) ------" autoComplete="off" 
          errorname="error error--header"
          value={tel}
          onInput={(e) => {setTel(e.target.value);  phoneMask(); onChangeDisabled()}}/>
          <Field id="text" name="text" placeholder="сообщение" as="textarea" className="feedback-header__textarea"
          value={text}
          onInput={(e) => setText(e.target.value)} />
          <ErrorMessage component="div" className="error" name="text" />
          <div className="feedback-header__box">
            <p className="feedback-header__text">
              Нажав на кнопку "получить предложение", вы подтверждаете, что полностью ознакомились с действующей информационной политикой на данном сайте, полностью согласны с ее положениями и дали все согласия, указанные в нижеупомянутой информационной политике
            </p>
            <button type="button" onClick={onSubmit} className="feedback-header__btn button button--red">Отправить</button>
          </div>
          </Form>
        </>
       
      )
    }

    const FooterForm = () => {
      const [nameVal, setName] = useState('');
      const [email, setEmail] = useState('');
      const [tel, setTel] = useState('');
      useEffect(() => {
        const sendFooterBtn = document.querySelector('.feedback--footer')
        sendFooterBtn.setAttribute('disabled', true)
      }, [])
      const onChangeDisabled = () => {
        let errorMessage = document.querySelectorAll('.error--footer');
        const sendFooterBtn = document.querySelector('.feedback--footer')
        if(nameVal && email && tel.length >= 16 && errorMessage.length == 0) {
          sendFooterBtn.removeAttribute('disabled')
        } else {
          sendFooterBtn.setAttribute('disabled', true)
        }
      }
      const [clazz, setClazz] = useState('feedback__responce');
    const onCloseResponce = () => {
      setClazz('feedback__responce');
    }
    const onSubmit = async function (event) {
      event.preventDefault();
      let formData = new FormData();
      formData.append("name", nameVal);
      formData.append("email", email);
      formData.append("tel", tel);
      let response = await fetch('/mailer/mail.php', {
          method: 'POST',
          body: formData
        });

      if (response.ok) {
          setName('');
          setEmail('')
          setTel('')
          setClazz('feedback__responce active')
      } else {
          console.log("error: " + response.status);
      }
    }
      return ( 
        <Form className="feedback" >
          <div className={clazz}>
            <button className="feedback__close" type="button" onClick={onCloseResponce}>
            <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63" fill="none">
            <circle cx="31.5" cy="31.5" r="31.5" fill="#E94235"/>
            <path d="M43.3345 21.707L19.9999 45.0416" stroke="white" stroke-width="3" stroke-linecap="round"/>
            <path d="M44.042 44.3345L20.7075 20.9999" stroke="white" stroke-width="3" stroke-linecap="round"/>
            </svg>
            </button>
            <div className="feedback__wrapper">
              <div className="feedback__logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="186" height="187" viewBox="0 0 186 187" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M93 0C93 51.6386 51.3625 93.5 0 93.5C51.3625 93.5 93 135.361 93 187C93 135.361 134.638 93.5 186 93.5C134.638 93.5 93 51.6386 93 0Z" fill="white"/>
                </svg>
              </div>
              <p>Спасибо, что написали нам, мы свяжемся с вами как можно скорее</p>
              <div className="feedback__logo feedback__logo--small">
                <svg xmlns="http://www.w3.org/2000/svg" width="62" height="63" viewBox="0 0 62 63" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M31 0C31 17.397 17.1208 31.5 0 31.5C17.1208 31.5 31 45.603 31 63C31 45.603 44.8792 31.5 62 31.5C44.8792 31.5 31 17.397 31 0Z" fill="white"/>
                </svg>
              </div>
            </div>
            
          </div>
          <h2 className="feedback__title">Обсудить проект</h2>
          <div ref={app}>
            <MyTextInputFooter
                id="name"
                name="name"
                type="text"
                placeholder="имя"
                errorname="error error--footer"
                autoComplete="off"
                value={nameVal}
                onInput={(e) => {setName(e.target.value); onChangeDisabled();}}
            />
            <MyTextInputFooter
                id="mail"
                name="email"
                errorname="error error--footer"
                type="email"
                placeholder="main@mail.com"
                autoComplete="off"
                value={email}
                onInput={(e) => {setEmail(e.target.value); onChangeDisabled()}}
            />
            <MyTextInputFooter
                id="tel"
                errorname="error error--footer"
                name="tel"
                type="tel"
                placeholder="+7 (9--) ------"
                autoComplete="off"
                value={tel}
                onInput={(e) => {setTel(e.target.value);  phoneMask(); onChangeDisabled()}}
            />
          </div>
          
      <div className="feedback__box">
          <button onClick={onSubmit} className="feedback__btn feedback--footer button button--red" type="button">Отправить</button>
          <p className="feedback__text">Нажав на кнопку "получить предложение", вы подтверждаете, что полностью ознакомились с действующей информационной политикой на данном сайте, полностью согласны с ее положениями и дали все согласия, указанные в вышеупомянутой информационной политике</p>
      </div>
      </Form>
      )
    }

  return (

    <Formik
      initialValues={{
        name: "",
        email: "",
        tel: "",
        text: "",
      }}
      validationSchema={Yup.object({
        name: Yup.string().min(2, "Минимум 2 символа для заполнения").required("Обязательное поле!"),
        email: Yup.string().email("Неправильный адрес электронной почты").required("Обязательное поле!"),
        tel: Yup.string().required("Обязательное поле!"),

        text: Yup.string().min(10, "Минимум 10 символов для заполнения"),
      })}
      onSubmit={() => console.log(FormData(form))}
    >
      {footer ? <FooterForm/> : <HeaderFeed/>}
    </Formik>
  );
};

export default FeedbackHeader;